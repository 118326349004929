import React from 'react'
import Kura from '../components/KuraProject'

const KuraPage = () => {
  
  return (
    <>
        <Kura />
    </>
  );
};

export default KuraPage;