import React, { useState } from 'react';
import Icon1 from '../../images/cpp.svg';
import Icon2 from '../../images/c.svg';
import Icon3 from '../../images/java.svg';
import Icon4 from '../../images/excel.png';
import Icon5 from '../../images/python.png';
import Icon6 from '../../images/html.png';
import Icon7 from '../../images/js.png';
import Icon8 from '../../images/css.png';
import Icon9 from '../../images/React.png';
import Icon10 from '../../images/php.png';
import Icon11 from '../../images/c.png';
import { ServicesContainer, ServicesH1, ServicesWrapper } from './ServicesElement';
import Card from './card';

const Services = () => {
	const [isFlipped, setIsFlipped] = useState(false);

	const handleClick = () => {
		setIsFlipped(!isFlipped);
	};
	const [isFlipped1, setIsFlipped1] = useState(false);

	const handleClick1 = () => {
		setIsFlipped1(!isFlipped1);
	};
	const [isFlipped2, setIsFlipped2] = useState(false);

	const handleClick2 = () => {
		setIsFlipped2(!isFlipped2);
	};
	const [isFlipped3, setIsFlipped3] = useState(false);

	const handleClick3 = () => {
		setIsFlipped3(!isFlipped3);
	};
	const [isFlipped4, setIsFlipped4] = useState(false);

	const handleClick4 = () => {
		setIsFlipped4(!isFlipped4);
	};
	const [isFlipped5, setIsFlipped5] = useState(false);

	const handleClick5 = () => {
		setIsFlipped5(!isFlipped5);
	};
	const [isFlipped6, setIsFlipped6] = useState(false);

	const handleClick6 = () => {
		setIsFlipped6(!isFlipped6);
	};

	const [isFlipped7, setIsFlipped7] = useState(false);

	const handleClick7 = () => {
		setIsFlipped7(!isFlipped7);
	};
	const [isFlipped8, setIsFlipped8] = useState(false);

	const handleClick8 = () => {
		setIsFlipped8(!isFlipped8);
	};
	const [isFlipped9, setIsFlipped9] = useState(false);

	const handleClick9 = () => {
		setIsFlipped9(!isFlipped9);
	};

	const [isFlipped10, setIsFlipped10] = useState(false);

	const handleClick10 = () => {
		setIsFlipped10(!isFlipped10);
	};

	return (
		<ServicesContainer id="skills">
			<ServicesH1>My Skills</ServicesH1>
			<ServicesWrapper>
				<Card name="C" button="Click for details" desc="I used this language for a school project to create a Proof of Concept and simulate a network with characteristics similar to NYM's." isFlipped={isFlipped10} handle={handleClick10} iconCard={Icon11} />
				<Card name="C++" button="Click for details" desc="I learned this language during my studies. I usually use it for my ongoing projects as it's the language used in the training" isFlipped={isFlipped} handle={handleClick} iconCard={Icon1} />
				<Card name="C#" button="Click for details" desc="I learned this language as a self-taught developer. I started programming in it when I began using Unity with the aim of creating my own video games. After many trial projects, I finally published one on Google Play" isFlipped={isFlipped1} handle={handleClick1} iconCard={Icon2} />
				<Card name="Java" button="Click for details" desc="I started to familiarize myself with Java, which is quite similar to C#, to develop Android applications. Moreover, I developed an application in Java with Android Studio as part of my BTS project" isFlipped={isFlipped2} handle={handleClick2} iconCard={Icon3} />
				<Card name="VBA" button="Click for details" desc="As part of my apprenticeship, I learned VBA to automate some redundant tasks or even to debug some Excel scripts" isFlipped={isFlipped7} handle={handleClick7} iconCard={Icon4} />
				<Card name="Python" button="Click for details" desc="To learn Python, I am developing a complex project which allows me to deeply understand this high-level language." isFlipped={isFlipped6} handle={handleClick6} iconCard={Icon5} />
				<Card name="HTML" button="Click for details" desc="In order to share my work, I started learning HTML." isFlipped={isFlipped3} handle={handleClick3} iconCard={Icon6} />
				<Card name="JS" button="Click for details" desc="To make my HTML pages even more dynamic, I started learning JS which allows me to create real-time events" isFlipped={isFlipped4} handle={handleClick4} iconCard={Icon7} />
				<Card name="CSS" button="Click for details" desc="In addition to HTML, I learned CSS to style my HTML pages to make them more attractive" isFlipped={isFlipped5} handle={handleClick5} iconCard={Icon8} />
				<Card name="PHP" button="Click for details" desc="During several internships or even for some website backends, I started learning PHP" isFlipped={isFlipped9} handle={handleClick9} iconCard={Icon10} />
				<Card name="React" button="Click for details" desc="I started learning React to create my portfolio and future web projects" isFlipped={isFlipped8} handle={handleClick8} iconCard={Icon9} />
			</ServicesWrapper>
		</ServicesContainer>
	);
};

export default Services;
