import React, { useState } from 'react';
import Video from '../../videos/video.mp4';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroBtnWrapper, HeroH1, HeroP, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElement';

const Hero = () => {
	const [hover, setHover] = useState(false);
	const onHover = () => {
		setHover(!hover);
	};

	return (
		<HeroContainer>
			<HeroBg>
				<VideoBg autoPlay loop muted src={Video} type="video/mp4" />
			</HeroBg>
			<HeroContent>
			<HeroH1>Thibault Pottier</HeroH1>
				<HeroP>
					Master's student in Computer Science
					<br />
					Captivated by video game development and blockchain technology.
				</HeroP>
				<HeroBtnWrapper>
					<Button to="about" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true" primarybtn="true" smooth={true} duration={500} spy={true} exact="true">
						Get Started {hover ? <ArrowForward /> : <ArrowRight />}
					</Button>
				</HeroBtnWrapper>
			</HeroContent>
		</HeroContainer>
	);
};

export default Hero;
