import svg from '../../images/present.svg';
import logo from '../../images/logoKura.png';
import ah from '../../images/chc.svg';
import prog from '../../images/prog.svg';
import design from '../../images/design.svg';
import logClass from '../../images/iconCornu.png';
import cdcClass from '../../images/Cdcclass.svg';
import desClass from '../../images/designClass.svg';
import progClass from '../../images/progClass.svg';
import QrCode from '../../images/frame.png';

export const homeObjOne = {
	id: 'about',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'About',
	headline: 'Introduction:',
	description: "I am 21 years old and a Master's student in Computer Science at the University of Bordeaux. I am passionate about Video Games and programming, and I aim to make it my profession. My professional goal is to become a Video Game / Blockchain Developer. That's why I started with a vocational high school diploma in Digital Systems and Networks, then moved on to a BTS in Computer Systems and Networks, followed by a General Computer Science License.",
	buttonLabel: 'Continue',
	imgStart: false,
	img: svg,
	alt: 'About',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'project',
	buttondirection: true,
};
export const homeObjTwo = {
	id: 'project',
	lightbg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Project',
	headline: 'Kura 2048 (Beta)',
	description: 'It\'s a "HyperCasual" type video game that I developed during my free time in C# on Unity.',
	buttonLabel: 'Click for details',
	imgStart: true,
	img: logo,
	alt: 'Car',
	dark: false,
	primary: true,
	darkText: true,
	direction: '/kuraproject',
	buttondirection: false,
};

export const homeObjThree = {
	id: 'project',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Project',
	headline: 'Ma Classe Entremêlée',
	description: "This is a project I carried out during my academic curriculum for the BTS examination.",
	buttonLabel: 'Click for details',
	imgStart: false,
	img: logClass,
	alt: 'CornuIcon',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: '/btsproject',
	buttondirection: false,
};
export const homeObjFour = {
	id: 'desc',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Description',
	headline: 'Kura 2048',
	description: 'Kura 2048 is a HyperCasual Game designed for mobile devices running on Android. This project was entirely developed in C# on Unity during my free time.',
	buttonLabel: 'Continue',
	imgStart: false,
	img: logo,
	alt: 'Kura2048',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'cdc',
	buttondirection: true,
};

export const homeObjFive = {
	id: 'cdc',
	lightbg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: '',
	headline: 'Project Specification',
	description: "For this project, I wanted to create a game on the Android platform. The goal was to recreate a 2048 game in a different form than its original, imposing a challenge to the player so they could finish their game and start another, making the game addictive without being too complicated or tedious.",
	buttonLabel: 'Continue',
	imgStart: true,
	img: ah,
	alt: 'Kura2048',
	dark: false,
	primary: true,
	darkText: true,
	direction: 'dev',
	buttondirection: true,
};

export const homeObjSix = {
	id: 'dev',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Development',
	headline: 'Programming',
	description: "Developed on Unity using the C# language, this project involved the use of APIs such as GooglePlay for retrieving user names and cloud saving, as well as LootLocker, which allowed for score leaderboard management and recording of some data.",
	buttonLabel: 'Continue',
	imgStart: false,
	img: prog,
	alt: 'Kura2048',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'design',
	buttondirection: true,
};

export const homeObjSeven = {
	id: 'design',
	lightbg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Development',
	headline: 'Design',
	description: "The design was remodeled several times during its testing phase, yet it remains quite basic despite its universe. More updates will follow to enhance user customization.",
	buttonLabel: 'Continue',
	imgStart: true,
	img: design,
	alt: 'Kura2048',
	dark: false,
	primary: true,
	darkText: true,
	direction: 'qrc',
	buttondirection: true,
};

export const homeObjDescC = {
	id: 'desc',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Description',
	headline: 'Ma Classe Entremêlée',
	description: 'This project was divided into several parts, one entirely focused on a web client and my part centered on an Android application.',
	buttonLabel: 'Continue',
	imgStart: false,
	img: logClass,
	alt: 'Kura2048',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'cdc',
	buttondirection: true,
};

export const homeObjCdc = {
	id: 'cdc',
	lightbg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: '',
	headline: 'Project Specification',
	description: "The Android application aims to improve student autonomy, thanks to this application that allows to enter useful information in real time, which can later be processed and analyzed by the web interface.",
	buttonLabel: 'Continue',
	imgStart: true,
	img: cdcClass,
	alt: 'Kura2048',
	dark: false,
	primary: true,
	darkText: true,
	direction: 'dev',
	buttondirection: true,
};

export const homeObjProg = {
	id: 'dev',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Development',
	headline: 'Programming',
	description: "The application was primarily developed in Java using Android Studio, with a section for database relations in PHP to manage the various data efficiently.",
	buttonLabel: 'Continue',
	imgStart: false,
	img: progClass,
	alt: 'Kura2048',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'design',
	buttondirection: true,
};

export const homeObjDesign = {
	id: 'design',
	lightbg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Development',
	headline: 'Design',
	description: "The design must be compelling to ensure that students who use it daily for their project activities do not become bored with it.",
	buttonLabel: 'Continue',
	imgStart: true,
	img: desClass,
	alt: 'Kura2048',
	dark: false,
	primary: true,
	darkText: true,
	direction: 'img',
	buttondirection: true,
};

export const homeObjQrCode = {
	id: 'qrc',
	lightbg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Google Play',
	headline: 'QrCode',
	description: 'Here is a small QR Code that you can scan to directly join the project on Google Play.',
	buttonLabel: 'Continue',
	imgStart: false,
	img: QrCode,
	alt: 'QrCode',
	dark: true,
	primary: true,
	darkText: false,
	primarybtn: 'true',
	direction: 'img',
	buttondirection: true,
};
